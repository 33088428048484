import Axios from 'axios';
import React from 'react';
import _ from 'lodash';

//Internal Libs
import { convertInputDate, removeCaseIdInitialNumber, isValidMarkShippedDate } from '../../../../common/functions';
import { getDateWithAddedBusinessDays } from '../../../../common/date';
import { userHasPermission } from '../../../../common/permission';

// Action Types
export const SET_SELECTED_DDM_SUBMITTED_DATE = 'SET_SELECTED_DDM_SUBMITTED_DATE';
export const SET_SELECTED_DDM_RECEIVED_DATE = 'SET_SELECTED_DDM_RECEIVED_DATE';
export const SET_SELECTED_PRODUCTION_RECEIVED_DATE = 'SET_SELECTED_PRODUCTION_RECEIVED_DATE';
export const OPEN_APPROVE_SHIPPING_MODAL = 'OPEN_APPROVE_SHIPPING_MODAL';
export const CLOSE_APPROVE_SHIPPING_MODAL = 'CLOSE_APPROVE_SHIPPING_MODAL';
export const OPEN_INTERNATIONAL_MODAL = 'OPEN_INTERNATIONAL_MODAL';
export const CLOSE_INTERNATIONAL_MODAL = 'CLOSE_INTERNATIONAL_MODAL';
export const CLOSE_IR_APPROVE_SHIPPING_MODAL = 'CLOSE_IR_APPROVE_SHIPPING_MODAL';
export const APPROVE_SHIPPING_PENDING = 'APPROVE_SHIPPING_PENDING';
export const APPROVE_SHIPPING_SUCCESS = 'APPROVE_SHIPPING_SUCCESS';
export const APPROVE_SHIPPING_ERROR = 'APPROVE_SHIPPING_ERROR';
export const APPROVE_IR_SHIPPING_PENDING = 'APPROVE_IR_SHIPPING_PENDING';
export const APPROVE_IR_SHIPPING_SUCCESS = 'APPROVE_IR_SHIPPING_SUCCESS';
export const APPROVE_IR_SHIPPING_ERROR = 'APPROVE_IR_SHIPPING_ERROR';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const OPEN_MARK_SHIPPED_MODAL = 'OPEN_MARK_SHIPPED_MODAL';
export const CLOSE_MARK_SHIPPED_MODAL = 'CLOSE_MARK_SHIPPED_MODAL';
export const OPEN_IR_MARK_SHIPPED_MODAL = 'OPEN_IR_MARK_SHIPPED_MODAL';
export const CLOSE_IR_MARK_SHIPPED_MODAL = 'CLOSE_IR_MARK_SHIPPED_MODAL';
export const SET_SELECTED_SHIP_DATE = 'SET_SELECTED_SHIP_DATE';
export const MARK_SHIPPED_IR_PENDING = 'MARK_SHIPPED_IR_PENDING';
export const MARK_SHIPPED_IR_SUCCESS = 'MARK_SHIPPED_IR_SUCCESS';
export const MARK_SHIPPED_IR_ERROR = 'MARK_SHIPPED_IR_ERROR';
export const SET_IR_SELECTED_SHIP_DATE = 'SET_IR_SELECTED_SHIP_DATE';
export const MARK_SHIPPED_PENDING = 'MARK_SHIPPED_PENDING';
export const MARK_SHIPPED_SUCCESS = 'MARK_SHIPPED_SUCCESS';
export const MARK_SHIPPED_ERROR = 'MARK_SHIPPED_ERROR';
export const OPEN_SHIPPING_LABEL_MODAL = 'OPEN_SHIPPING_LABEL_MODAL';
export const CLOSE_SHIPPING_LABEL_MODAL = 'CLOSE_SHIPPING_LABEL_MODAL';
export const FETCH_CASES_PENDING = 'FETCH_CASES_PENDING';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_ERROR = 'FETCH_CASES_ERROR';
export const UPDATE_MARK_SHIPPED_CASES = 'UPDATE_MARK_SHIPPED_CASES';
export const SET_SHIP_DATE_ERROR = 'SET_SHIP_DATE_ERROR';
export const BATCH_MARK_SHIPPED_SUCCESS = 'BATCH_MARK_SHIPPED_SUCCESS';
export const BATCH_MARK_SHIPPED_ERROR = 'BATCH_MARK_SHIPPED_ERROR';
export const BATCH_ALL_FAILED_MARK_SHIPPED_ERROR = 'BATCH_ALL_FAILED_MARK_SHIPPED_ERROR';
export const OPEN_BATCH_LABELS_MODAL = 'OPEN_BATCH_LABELS_MODAL';
export const CLOSE_BATCH_LABELS_MODAL = 'CLOSE_BATCH_LABELS_MODAL';
export const FETCH_COMPLETED_BATCHES_PENDING = 'FETCH_COMPLETED_BATCHES_PENDING';
export const FETCH_COMPLETED_BATCHES_SUCCESS = 'FETCH_COMPLETED_BATCHES_SUCCESS';
export const FETCH_COMPLETED_BATCHES_ERROR = 'FETCH_COMPLETED_BATCHES_ERROR';
export const OPEN_BATCH_LABELS_MODAL_FROM_LIST = 'OPEN_BATCH_LABELS_MODAL_FROM_LIST';
export const OPEN_SESSION_EXPIRE_MODAL = 'OPEN_SESSION_EXPIRE_MODAL';

const SHIPPING_BUSINESS_DAYS = {
  'Ground Shipping': 5,
  '3 Day Shipping': 3,
  '2 Day Shipping': 2,
  'Next Day Shipping': 1,
};

// Action Creators
// -----------------------------------------------------------------------------

export function toggleModal(modal_name, modal_value) {
  return {
    type: TOGGLE_MODAL,
    modal_name,
    modal_value,
  };
}

export function setSelectedDDMSubmittedDate(selected_ddm_submitted_date) {
  return {
    type: SET_SELECTED_DDM_SUBMITTED_DATE,
    selected_ddm_submitted_date: selected_ddm_submitted_date,
  };
}

export function setSelectedDDMReceivedDate(selected_ddm_received_date) {
  return {
    type: SET_SELECTED_DDM_RECEIVED_DATE,
    selected_ddm_received_date: selected_ddm_received_date,
  };
}

export function setSelectedProductionReceivedDate(selected_production_received_date) {
  return {
    type: SET_SELECTED_PRODUCTION_RECEIVED_DATE,
    selected_production_received_date: selected_production_received_date,
  };
}

/**
 * Handles the event for when the user changes the mark shipped date
 * @function
 * @param {event} e Button click event
 */
export function onShipDateChange(e) {
  return (dispatch, getState) => {
    const {
      selected_production_received_date,
      selected_case_id,
      case_details,
      ir_mark_shipped_modal,
      selected_ireq,
      mark_shipped_cases,
      batch_mark_shipped_modal,
    } = getState().shippingReducer;

    let mark_shipped_cases_updates = [];
    const ship_date = e.target.value;

    // Need to update estimated delivery dates when ship date changes
    if (batch_mark_shipped_modal) {
      mark_shipped_cases_updates = formatBatchShippingCases(mark_shipped_cases, ship_date);
    } else if (ir_mark_shipped_modal) {
      mark_shipped_cases_updates = formatIrDataForMarkShippedModal(case_details, selected_ireq, ship_date);
    } else {
      mark_shipped_cases_updates = formatCaseDataForMarkShippedModal(
        selected_case_id,
        case_details,
        selected_production_received_date,
        ship_date,
        mark_shipped_cases
      );
    }

    dispatch({
      type: SET_SELECTED_SHIP_DATE,
      selected_ship_date: e.target.value,
      mark_shipped_cases: mark_shipped_cases_updates,
    });
  };
}

/**
 * Formats cases/ireqs for batch shipping page (sorts shipping rates)
 * @function
 * @param {list} batch_cases - List of batch shipping cases/ireqs
 * @param {string} selected_ship_date - Current selected ship date
 * @returns {list} JList of formatted cases/ireqs
 */
export function formatBatchShippingCases(batch_cases, selected_ship_date) {
  let batch_cases_updates = _.cloneDeep(batch_cases);
  for (let cases of batch_cases_updates) {
    const preferred_shipping = cases.preferred_shipping ? cases.preferred_shipping : null;
    cases.shippo_rates = getShippoRates(cases, selected_ship_date, preferred_shipping);
  }
  return batch_cases_updates;
}

/**
 * Gets the current date used as the default for the ship date picker
 * @function
 * @return {String} Current date in the correct format
 */
export function getCurrentDate() {
  let date = new Date();
  return convertInputDate(date.toISOString());
}

// Modals

export function openApproveShippingModal() {
  return {
    type: OPEN_APPROVE_SHIPPING_MODAL,
  };
}

export function openInternationalModal() {
  return {
    type: OPEN_INTERNATIONAL_MODAL,
  }
}

export function closeInternationalModal() {
  return {
    type: CLOSE_INTERNATIONAL_MODAL,
  }
}

export function closeApproveShippingModal() {
  return {
    type: CLOSE_APPROVE_SHIPPING_MODAL,
  };
}

export function closeIrApproveShippingModal() {
  return {
    type: CLOSE_IR_APPROVE_SHIPPING_MODAL,
  };
}

/**
 * Opens the modal to show the Batch Labels PDF
 * @function
 * @return {Object} - Action type
 */
export function openBatchLabelsModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_MARK_SHIPPED_MODAL,
      mark_shipped_cases: [],
    });
    dispatch({
      type: OPEN_BATCH_LABELS_MODAL,
    });
  };
}

export function openBatchLabelsModalFromList(batch_labels_file) {
  return {
    type: OPEN_BATCH_LABELS_MODAL_FROM_LIST,
    batch_labels_file: batch_labels_file,
  };
}

/**
 * Closes the modal that shows the Batch Labels PDF
 * @function
 * @return {Object} - Action type
 */
export function closeBatchLabelsModal() {
  return {
    type: CLOSE_BATCH_LABELS_MODAL,
  };
}

/**
 * Checks to see if case is already in batch process. If not, opens the mark shipped modal from case details. If yes, throw session expire
 * @function
 * @param selected_case_id {String} - The case id
 * @param case_details {Object} - Case details object
 * @return {Function} - Action that triggers a dispatch
 */
export function openMarkShippedModal(selected_case_id, case_details) {
  return (dispatch, getState) => {
    Axios.get(`/apiv3/case/${selected_case_id}/ship/markshipped`)
      .then((res) => {
        const { selected_production_received_date, selected_ship_date } = getState().shippingReducer;
        dispatch({
          type: OPEN_MARK_SHIPPED_MODAL,
          selected_ship_date: getCurrentDate(),
          mark_shipped_cases: formatCaseDataForMarkShippedModal(selected_case_id, case_details, selected_production_received_date, selected_ship_date),
          case_details: case_details,
          selected_case_id: selected_case_id,
          batch_mark_shipped_modal: false,
        });
      })

      .catch((error) => {
        dispatch({
          type: OPEN_SESSION_EXPIRE_MODAL,
        });
      });
  };
}

export function closeMarkShippedModal(type) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    dispatch({
      type: CLOSE_MARK_SHIPPED_MODAL,
      mark_shipped_cases: type === 'batch' ? mark_shipped_cases : [],
    });
  };
}

/**
 * Checks to see if item request is already in batch process. If not, opens the mark shipped modal from item request details. If yes, throw session expire
 * @function
 * @param item_request {Object} - Item request object
 * @param case_details {Object} - Case details object
 * @return {Object} - Action
 */
export function openIrMarkShippedModal(item_request, case_details) {
  return (dispatch, getState) => {
    Axios.get(`/apiv3/ireq/${item_request.ireq_id}/ship/markshipped`)
      .then((res) => {
        const { selected_ship_date } = getState().shippingReducer;

        dispatch({
          type: OPEN_IR_MARK_SHIPPED_MODAL,
          selected_ship_date: getCurrentDate(),
          mark_shipped_cases: formatIrDataForMarkShippedModal(case_details, item_request, selected_ship_date),
          case_details: case_details,
          selected_ireq: item_request,
        });
      })

      .catch((error) => {
        dispatch({
          type: OPEN_SESSION_EXPIRE_MODAL,
        });
      });
  };
}

export function closeIrMarkShippedModal(type) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    dispatch({
      type: CLOSE_IR_MARK_SHIPPED_MODAL,
      mark_shipped_cases: type === 'batch' ? mark_shipped_cases : [],
    });
  };
}

export function openShippingLabelModal() {
  return {
    type: OPEN_SHIPPING_LABEL_MODAL,
  };
}

export function closeShippingLabelModal() {
  return {
    type: CLOSE_SHIPPING_LABEL_MODAL,
  };
}

export function openBatchShippingMarkShippedModal(cases) {
  return {
    type: OPEN_MARK_SHIPPED_MODAL,
    selected_ship_date: getCurrentDate(),
    mark_shipped_cases: cases,
    batch_mark_shipped_modal: true,
  };
}

// Axios

/**
 * Calls the approve shipping api to validate the address
 * @function
 * @param {String} case_id - Id of the case we want to approve shipping for
 * @param {Function} reload_information - reloadInformation() function from case_details.js
 */
export function onApproveShippingButtonClick(case_id, reload_information) {
  return (dispatch) => {
    dispatch(approveShippingPending());

    Axios.put(`/apiv3/case/${case_id}/ship/approve`)
      .then((res) => {
        dispatch(approveShippingSuccess(res.data));
        reload_information();
      })
      .catch((error) => {
        dispatch(approveShippingError(error));
      });
  };
}

export function approveShippingPending() {
  return {
    type: APPROVE_SHIPPING_PENDING,
  };
}

/**
 * Sets the approve shipping as successful and sets the message to be displayed in the Approve Shipping modal
 * @function
 * @param {String} address_is_valid - 'True' or 'False' depending on result from API call
 * @param {String} address - Full address used for setting the approve shipping result message
 */
export function approveShippingSuccess(address_is_valid) {
  return {
    type: APPROVE_SHIPPING_SUCCESS,
    approve_shipping_message: getApproveShippingResultMessage(address_is_valid),
  };
}

/**
 * Sets the approve shipping as failed
 * @function
 * @param {Error} error - The error object from the API call
 */
export function approveShippingError(error) {
  const refresh = error.response.status === 409 ? true : false;
  return {
    type: APPROVE_SHIPPING_ERROR,
    error: true,
    refresh: refresh,
    approve_shipping_message: getApproveShippingServiceErrorMessage('case'),
  };
}

/**
 * Gets estimated arrival date from selected shipping selection
 * @function
 * @param {object} selected_case - Selected case
 * @param {string} selected_object_id - Selected shippo rate object id
 * @returns {string} - Estimated arrival date
 */
export function getSelectedEstimatedArrivalDate(selected_case, selected_object_id) {
  if (!selected_object_id && selected_case.shippo_rates.length > 0) {
    return selected_case.shippo_rates[0].estimated_arrival_date;
  }
  for (const rate of selected_case.shippo_rates) {
    if (rate.object_id === selected_object_id) {
      return rate.estimated_arrival_date;
    }
  }
  return null;
}

/**
 * Marks the case as shipped by calling the mark_shipped api
 * @function
 * @param {String} case_id The id of the case
 * @param {Function} reload_information - reloadInformation() function from case_details.js
 */
export function onMarkShippedButtonClick(case_id, reload_information, selected_address_id) {
  return (dispatch, getState) => {
    let { selected_ship_date, mark_shipped_cases } = getState().shippingReducer;

    if (!isValidMarkShippedDate(mark_shipped_cases, selected_ship_date)) {
      dispatch({ type: SET_SHIP_DATE_ERROR });
    } else {
      dispatch(markShippedPending());
      const data = {
        hand_delivery: mark_shipped_cases[0].hand_delivery,
        selected_ship_date: selected_ship_date,
        tracking_number: mark_shipped_cases[0].tracking_number,
        object_id: mark_shipped_cases[0].object_id,
        shipping_service: mark_shipped_cases[0].shipping_service,
        shipping_method: mark_shipped_cases[0].shipping_method,
        est_arrival_date: getSelectedEstimatedArrivalDate(mark_shipped_cases[0], mark_shipped_cases[0].object_id),
      };

      let slug = '';
      if (data.hand_delivery) {
        if (mark_shipped_cases[0].id.includes('-DE')) {
          slug = 'de-hand-delivered-2020-12-16';
        } else {
          slug = 'case-hand-delivered-2020-10-28';
        }
      } else {
        slug = 'ss-inbrace-case-has-shipped-fedex-1';
      }

      Axios.put(`/apiv3/case/${case_id}/ship/markshipped`, data)
        .then((res) => {
          Axios.post(`/api/email/?slug=${slug}&caseId=${removeCaseIdInitialNumber(case_id)}&addressId=${selected_address_id}&method=standard`);
          dispatch(markShippedSuccess());
          reload_information();
        })
        .catch((error) => {
          dispatch(markShippedError(error));
        });
    }
  };
}

export function markShippedPending() {
  return {
    type: MARK_SHIPPED_PENDING,
  };
}

export function markShippedSuccess() {
  return {
    type: MARK_SHIPPED_SUCCESS,
  };
}

export function markShippedError(error) {
  const refresh = error.response.status === 409 ? true : false;
  return {
    type: MARK_SHIPPED_ERROR,
    error: true,
    refresh: refresh,
  };
}

/**
 * Handles when the batch process is successful after marking shipped
 * @function
 * @param {String} batch_labels_file - File path for the combined batch labels PDF
 * @return {Object} Action type and batch labels file
 */
export function batchMarkShippedSuccess(batch_labels_file) {
  return {
    type: BATCH_MARK_SHIPPED_SUCCESS,
    batch_labels_file: batch_labels_file,
  };
}

/**
 * Handles when the batch process failed
 * @function
 * @param {Array} batch_failed_ids - Array of case/item request IDs that failed from the batch process
 * @param {String} batch_labels_file - File path for the combined batch labels PDF
 * @param {Boolean} only_non_automatic_cases_succeded - Flag for whether only non-automatic cases succeeded from the batch (will be used for result modal)
 * @param {Array} non_automatic_cases - Array of case/item request IDs that were manually shipped or hand delivered
 * @return {Object} Action type
 */
export function batchMarkShippedError(batch_failed_ids, batch_labels_file, only_non_automatic_cases_succeded, non_automatic_cases) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    mark_shipped_cases_updates = mark_shipped_cases_updates.filter(function (cases) {
      return non_automatic_cases.indexOf(cases.id) === -1;
    });

    dispatch({
      type: BATCH_MARK_SHIPPED_ERROR,
      batch_failed_ids: batch_failed_ids,
      batch_labels_file: batch_labels_file,
      only_non_automatic_cases_succeded: only_non_automatic_cases_succeded,
      mark_shipped_cases: mark_shipped_cases_updates,
    });
  };
}

/**
 * Handles when all the cases/item requests in the batch failed the mark shipped process
 * @function
 * @return {Object} - Action type
 */
export function batchAllFailedMarkShippedError() {
  return {
    type: BATCH_ALL_FAILED_MARK_SHIPPED_ERROR,
  };
}

/**
 * Marks the cases and item requests as shipped using the batch process
 * @function
 */
export function onBatchMarkShippedButtonClick() {
  return (dispatch, getState) => {
    let { selected_ship_date, mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);
    if (!isValidMarkShippedDate(mark_shipped_cases, selected_ship_date)) {
      dispatch({
        type: SET_SHIP_DATE_ERROR,
        ship_date_error: true,
      });
    } else {
      dispatch(markShippedPending());

      for (const cases of mark_shipped_cases_updates) {
        if (!cases.object_id && cases.shippo_rates && cases.shippo_rates.length > 0) {
          cases.object_id = cases.shippo_rates[0].object_id;
          cases.est_arrival_date = cases.shippo_rates[0].estimated_arrival_date;
        }
      }

      const data = {
        records: mark_shipped_cases_updates,
        selected_ship_date: selected_ship_date,
      };

      Axios.put('/apiv3/batchshipping/markshipped', data)
        .then((res) => {
          let non_automatic_cases = [];
          for (const record of data.records) {
            if (record.hand_delivery || record.tracking_number) {
              non_automatic_cases.push(record.id);
              let slug = 'case-hand-delivered-2020-10-28';

              if (record.hand_delivery) {
                if (record.id.includes('-IR')) {
                  slug = 'ir-hand-delivered';
                } else if (record.id.includes('-DE')) {
                  slug = 'de-hand-delivered-2020-12-16';
                }
              } else if (record.tracking_number) {
                if (record.id.includes('-IR')) {
                  slug = 'ir-shipped-1';
                } else {
                  slug = 'ss-inbrace-case-has-shipped-fedex-1';
                }
              }

              if (record.id.includes('-IR')) {
                Axios.post(`/api/email/?slug=${slug}&ireqId=${record.id}&method=standard&provider=${window.location.origin}`);
              } else {
                Axios.post(`/api/email/?slug=${slug}&caseId=${removeCaseIdInitialNumber(record.id)}&addressId=${record.shipping_address_id}&method=standard`);
              }
            }
          }

          if (res.data.fetch_status_timeout) {
            setTimeout(function () {
              dispatch(fetchBatchShippingStatus(res.data.batch_id, 1, res.data.fetch_status_timeout, non_automatic_cases));
            }, res.data.fetch_status_timeout);
          } else {
            dispatch(batchMarkShippedSuccess(res.data.labels_path));
            dispatch(fetchAllCasesReadyForShipping());
          }
        })
        .catch((error) => {
          dispatch(markShippedError(error));
        });
    }
  };
}

/**
 * Fetches for batch shipping status update
 * @function
 * @param {String} batch_id - ID of the batch
 * @param {Number} counter - Counter of how many times api was called
 * @param {Number} timer - Settimeout interval in ms for calling the api
 * @param {Array} non_automatic_cases - Array of case/item request IDs that were manually shipped or hand delivered
 */
function fetchBatchShippingStatus(batch_id, counter, timer, non_automatic_cases) {
  return (dispatch) => {
    Axios.get(`/apiv3/batchshipping/${batch_id}`)
      .then((res) => {
        const status = res.data.status;

        if (status === 'Success') {
          dispatch(batchMarkShippedSuccess(res.data.labels_path));
          dispatch(openBatchLabelsModal());
          dispatch(fetchAllCasesReadyForShipping());
          dispatch(fetchCompletedBatches());
        } else if (status === 'Processing') {
          if (counter < 2) {
            setTimeout(function () {
              dispatch(fetchBatchShippingStatus(batch_id, counter + 1, timer, non_automatic_cases));
            }, timer);
          } else {
            dispatch(batchAllFailedMarkShippedError());
          }
        } else if (status === 'Failed') {
          if (res.data.label_count === res.data.batch_failed_ids.length && non_automatic_cases.length === 0) {
            dispatch(batchAllFailedMarkShippedError());
          } else {
            const only_non_automatic_cases_succeded = res.data.label_count === res.data.batch_failed_ids.length;
            dispatch(batchMarkShippedError(res.data.batch_failed_ids, res.data.labels_path, only_non_automatic_cases_succeded, non_automatic_cases));
            dispatch(fetchAllCasesReadyForShipping());
            dispatch(fetchCompletedBatches());
          }
        }
      })

      .catch((error) => {
        dispatch(batchMarkShippedError([]));
      });
  };
}

/**
 * Fetches for all completed batches
 * @function
 */
export function fetchCompletedBatches() {
  return (dispatch) => {
    dispatch(fetchCompletedBatchesPending());
    Axios.get(`/apiv3/batchshipping`)
      .then((res) => {
        dispatch(fetchCompletedBatchesSuccess(res.data.batches));
      })

      .catch((error) => {
        dispatch(fetchCompletedBatchesError());
      });
  };
}

export function fetchCompletedBatchesPending() {
  return {
    type: FETCH_COMPLETED_BATCHES_PENDING,
  };
}

export function fetchCompletedBatchesSuccess(batches) {
  return {
    type: FETCH_COMPLETED_BATCHES_SUCCESS,
    batches: batches,
  };
}

export function fetchCompletedBatchesError() {
  return {
    type: FETCH_COMPLETED_BATCHES_ERROR,
  };
}

/**
 * Calls the approve shipping api to validate the address
 * @function
 * @param {String} ireq_id - Id of the case we want to approve shipping for
 * @param {Function} reload_information - reloadInformation() function from case_details.js
 */
export function onIrApproveShippingButtonClick(ireq_id, reload_information) {
  return (dispatch) => {
    dispatch(approveIrShippingPending());

    Axios.put(`/apiv3/ireq/${ireq_id}/ship/approve`)
      .then((res) => {
        dispatch(approveIrShippingSuccess(res.data));
        reload_information();
      })
      .catch((error) => {
        dispatch(approveIrShippingError(error));
      });
  };
}

export function approveIrShippingPending() {
  return {
    type: APPROVE_IR_SHIPPING_PENDING,
  };
}

/**
 * Sets the approve shipping as successful and sets the message to be displayed in the Approve Shipping modal
 * @function
 * @param {String} address_is_valid - 'True' or 'False' depending on result from API call
 * @param {String} address - Full address used for setting the approve shipping result message
 */
export function approveIrShippingSuccess(address_is_valid) {
  return {
    type: APPROVE_IR_SHIPPING_SUCCESS,
    approve_shipping_message: getIrApproveShippingResultMessage(address_is_valid),
  };
}

/**
 * Sets the approve shipping as failed
 * @function
 * @param {Error} error - The error object from the API call
 */
export function approveIrShippingError(error) {
  const refresh = error.response.status === 409 ? true : false;

  return {
    type: APPROVE_IR_SHIPPING_ERROR,
    error: true,
    refresh: refresh,
    ir_approve_shipping_message: getApproveShippingServiceErrorMessage('item request'),
  };
}

/**
 * Calls the mark shipped api to create shipping label
 * @function
 * @param {String} ireq_id - Id of the item request we want to ship
 * @param {Function} reload_information - reloadInformation() function from case_details.js
 */
export function onIrMarkShippedButtonClick(ireq_id, reload_information) {
  return (dispatch, getState) => {
    let { mark_shipped_cases, selected_ship_date } = getState().shippingReducer;
    if (!isValidMarkShippedDate(mark_shipped_cases, selected_ship_date)) {
      dispatch({ type: SET_SHIP_DATE_ERROR });
    } else {
      dispatch(markShippedIrPending());
      const hand_delivery = mark_shipped_cases[0].hand_delivery;
      const data = {
        hand_delivery: hand_delivery,
        selected_ship_date: selected_ship_date,
        tracking_number: mark_shipped_cases[0].tracking_number,
        object_id: mark_shipped_cases[0].object_id,
        shipping_service: mark_shipped_cases[0].shipping_service,
        shipping_method: mark_shipped_cases[0].shipping_method,
      };

      const slug = hand_delivery ? 'ir-hand-delivered' : 'ir-shipped-1';
      Axios.put(`/apiv3/ireq/${ireq_id}/ship/markshipped`, data)
        .then((res) => {
          Axios.post(`/api/email/?slug=${slug}&ireqId=${ireq_id}&method=standard&provider=${window.location.origin}`);
          dispatch(markShippedIrSuccess());
          reload_information();
        })
        .catch((error) => {
          dispatch(markShippedIrError(error));
        });
    }
  };
}

export function markShippedIrPending() {
  return {
    type: MARK_SHIPPED_IR_PENDING,
  };
}

/**
 * Sets the tracking url and tracking number and label file for shipped item request
 * @function
 */
export function markShippedIrSuccess() {
  return {
    type: MARK_SHIPPED_IR_SUCCESS,
  };
}

/**
 * Sets the mark shipped as failed
 * @function
 * @param {Error} error - The error object from the API call
 */
export function markShippedIrError(error) {
  const refresh = error.response.status === 409 ? true : false;

  return {
    type: MARK_SHIPPED_IR_ERROR,
    error: true,
    refresh: refresh,
  };
}

/**
 * Retrieves all cases ready for shipping (to display on batch shipping page)
 * @function
 */
export function fetchAllCasesReadyForShipping() {
  return (dispatch, getState) => {
    dispatch(fetchCasesPending());

    Axios.get('/apiv3/batchshipping/getall')
      .then((res) => {
        const { selected_ship_date } = getState().shippingReducer;
        const statuses = {
          approve_for_shipping: res.data.approve_for_shipping_count,
          mark_shipped: res.data.mark_shipped_count,
        };

        const batch_shipping_cases = formatBatchShippingCases(res.data.cases, selected_ship_date);
        dispatch(fetchCasesSuccess(batch_shipping_cases, statuses));
      })
      .catch((error) => {
        dispatch(fetchCasesError(error));
      });
  };
}

export function fetchCasesPending() {
  return {
    type: FETCH_CASES_PENDING,
  };
}

export function fetchCasesSuccess(cases, statuses) {
  return {
    type: FETCH_CASES_SUCCESS,
    all_cases: cases,
    statuses: statuses,
  };
}

export function fetchCasesError(error) {
  return {
    type: FETCH_CASES_ERROR,
    error: error,
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {Boolean} id - The case id
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function onHandDeliveryFlagChange(id) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.hand_delivery = !cases.hand_delivery;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}
/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case id
 * @param {Boolean} flag - Contains the marker to set true or false
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setHandDeliveryFlagChange(id, flag) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.hand_delivery = flag;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}
/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case id
 * @param {String} object_id - Contains the object_id for a given shippo rate
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setShippoObjectId(id, object_id, est_arrival_date) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.object_id = object_id;
        cases.est_arrival_date = est_arrival_date;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}
/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case id
 * @param {String} tracking_number - Contains the manually entered tracking number
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setTrackingNumber(id, tracking_number) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    if (tracking_number.length > 40) {
      tracking_number = tracking_number.substring(0, 40);
    }

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.tracking_number = tracking_number;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case/item request id
 * @param {Boolean} options_menu - Contains the marker to set true or false
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setOptionsMenuFlag(id, options_menu) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.options_menu = options_menu;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case/item request id
 * @param {String} shipping_method - Contains the selected shipping method
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setShippingMethod(id, shipping_method) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.shipping_method = shipping_method;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case/item request id
 * @param {String} shippo_selection - Contains the selected delivery method/rate
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setShippoSelection(id, shippo_selection) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.shippo_selection = shippo_selection;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case/item request id
 * @param {Boolean} shippo_selection_menu - Contains the marker to set true or false
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setShippoSelectionMenu(id, shippo_selection_menu) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.shippo_selection_menu = shippo_selection_menu;
        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Returns UPDATE_MARK_SHIPPED_CASES action for the reducer
 * @function
 * @param {String} id - The case id
 * @param {String} shipping_service - Contains the shipping service string
 * @return {Object} - Returns action and updated mark_shipped_cases
 */
export function setShippingService(id, shipping_service) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    for (const cases of mark_shipped_cases_updates) {
      if (cases.id === id) {
        cases.shipping_service = shipping_service;

        break;
      }
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Determines the correct message to display on the Approve Shipping modal
 * @function
 * @param {Boolean} address_is_valid - Whether or not the address is valid (determined by api call)
 * @return {JSX} JSX element that includes the result message (will be used in modal.js)
 */
function getApproveShippingResultMessage(address_is_valid) {
  if (address_is_valid === 'True') {
    return <div>Case successfully approved and ready to ship</div>;
  } else if (address_is_valid === 'international') {
    return <div>For international orders please ship through Netsuite.</div>;
  } else {
    return <div>Invalid address. Please update the address and try again.</div>;
  }
}

/**
 * Determines the correct message to display on the Approve Shipping modal
 * @function
 * @param {Boolean} address_is_valid - Whether or not the address is valid (determined by api call)
 * @return {JSX} JSX element that includes the result message (will be used in modal.js)
 */
function getIrApproveShippingResultMessage(address_is_valid) {
  if (address_is_valid === 'True') {
    return <div>Item Request successfully approved and ready to ship</div>;
  } else if (address_is_valid === 'international') {
    return <div>For international orders please ship through Netsuite.</div>;
  } else {
    return <div>Invalid address. Please update the address and try again.</div>;
  }
}

/**
 * Returns the message to display on the Approve Shipping modal if there was a service error
 * @function
 * @param {String} type - case or item request
 * @return {JSX} JSX element that includes the result message (will be used in modal.js)
 */
function getApproveShippingServiceErrorMessage(type) {
  return (
    <div>
      The {type} was not successfully approved for the following reason:
      <br />
      <b>Shippo Service Error</b>
      <br />
      Please try again later.
    </div>
  );
}

/**
 * When a case or item request is selected from the mark shipped page
 * @function
 * @param {Object} selected_case - case or item request object
 * @return {Function} Action that triggers a dispatch
 */
export function onBatchShippingCaseSelect(selected_case) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    if (mark_shipped_cases_updates.filter((c) => c.id === selected_case.id).length > 0) {
      mark_shipped_cases_updates = mark_shipped_cases_updates.filter(function (item) {
        return item.id !== selected_case.id;
      });
    } else {
      mark_shipped_cases_updates.push(selected_case);
    }

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * When al cases/item requests are selected on the mark shipped page
 * @function
 * @param {Object} event - Event object
 * @param {Array} cases - List of cases/item requests
 * @param {Object} user_roles_and_permissions - User's roles and permissions
 * @return {Function} Action that triggers a dispatch
 */
export function onBatchShippingCaseSelectAll(event, cases, user_roles_and_permissions) {
  return (dispatch, getState) => {
    const { mark_shipped_cases } = getState().shippingReducer;
    let mark_shipped_cases_updates = _.cloneDeep(mark_shipped_cases);

    let allowed_cases = _.cloneDeep(cases);

    allowed_cases = allowed_cases.filter((allowed_case) => !allowed_case.mark_shipped_in_progress);

    if (!userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) {
      allowed_cases = allowed_cases.filter((allowed_case) => !allowed_case.id.includes('-IR'));
    }
    if (!userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) {
      allowed_cases = allowed_cases.filter((allowed_case) => allowed_case.id.includes('-IR'));
    }

    mark_shipped_cases_updates = event.target.checked ? allowed_cases : [];

    dispatch({
      type: UPDATE_MARK_SHIPPED_CASES,
      mark_shipped_cases_updates: mark_shipped_cases_updates,
    });
  };
}

/**
 * Clears selection of cases to be mark shipped for the batch process
 * @function
 * @return {Object} Action that triggers a dispatch
 */
export function clearMarkShippedCases() {
  return {
    type: UPDATE_MARK_SHIPPED_CASES,
    mark_shipped_cases_updates: [],
  };
}

/**
 * Formats the data of a case to be displayed on the Mark Shipped modal in the batch process
 * @function
 * @param {String} id - Case id
 * @param {Object} case_details - Case details
 * @param {String} production_received_date - The case's production received date
 * @param {String} selected_ship_date - Current selected ship date
 * @return {Object} The formatted data
 */
function formatCaseDataForMarkShippedModal(id, case_details, production_received_date, selected_ship_date, prev_mark_shipped_cases = []) {
  const selected_case = getSelectedCase(id, case_details);
  const preferred_shipping = selected_case && selected_case.preferred_shipping ? selected_case.preferred_shipping : null;

  const shippo_rates = getShippoRates(selected_case, selected_ship_date, preferred_shipping);
  const tracking_number = selected_case && selected_case.ship_process.tracking_number ? selected_case.ship_process.tracking_number : '';
  const tracking_url = selected_case && selected_case.tracking_url ? selected_case.tracking_url : '';
  let shipping_method = tracking_number || shippo_rates.length === 0 ? 'manual_shipping' : 'automated_shipping';
  if (prev_mark_shipped_cases) {
    const prev_case = prev_mark_shipped_cases.find((c) => c.id === id);
    if (prev_case) {
      shipping_method = prev_case.shipping_method;
    }
  }

  return [
    {
      id: id,
      doctor: case_details.dso_doctor ? case_details.dso_doctor : case_details.doctor,
      first_name: case_details.patient.first_name,
      last_name: case_details.patient.last_name,
      shippo_rates: shippo_rates,
      hand_delivery: false,
      tracking_number: tracking_number,
      production_received_date: production_received_date,
      shippo_selection: '',
      shippo_selection_menu: false,
      options_menu: false,
      shipping_method: shipping_method,
      tracking_url: tracking_url,
      shipping_service: tracking_url ? 'FedEx' : '',
    },
  ];
}

/**
 * Formats the data of an item request to be displayed on the Mark Shipped modal in the batch process
 * @function
 * @param {Object} case_details - Case details
 * @param {String} item_request - Item request object
 * @param {String} selected_ship_date - Current selected ship date
 * @return {Object} The formatted data
 */
function formatIrDataForMarkShippedModal(case_details, item_request, selected_ship_date) {
  const preferred_shipping = item_request && item_request.preferred_shipping ? item_request.preferred_shipping : null;
  const shippo_rates = getShippoRates(item_request, selected_ship_date, preferred_shipping);

  const approval_date = getIrApprovalDate(case_details, item_request);
  return [
    {
      id: item_request.ireq_id,
      doctor: case_details.dso_doctor ? case_details.dso_doctor : case_details.doctor,
      first_name: case_details.patient.first_name,
      last_name: case_details.patient.last_name,
      shippo_rates: shippo_rates,
      hand_delivery: false,
      tracking_number: item_request.tracking_info ? item_request.tracking_info : '',
      shippo_selection: '',
      shippo_selection_menu: false,
      options_menu: false,
      shipping_method: item_request.tracking_info || shippo_rates.length === 0 ? 'manual_shipping' : 'automated_shipping',
      tracking_url: item_request.tracking_url ? item_request.tracking_url : '',
      shipping_service: item_request.tracking_url ? 'FedEx' : '',
      approval_date: approval_date,
    },
  ];
}

/**
 * Gets shippo rates for case or item request
 * @function
 * @param {object} selected_case_or_ireq - Selected case or item request object
 * @param {string} selected_ship_date - Current selected ship date
 * @param {string} preferred_shipping - Preferred shipping method for case/ireq
 * @returns {Array} Return shippo rates
 */
function getShippoRates(selected_case_or_ireq, selected_ship_date, preferred_shipping) {
  const target_business_days = preferred_shipping ? SHIPPING_BUSINESS_DAYS[preferred_shipping] : null;
  const target_arrival_date = target_business_days ? getDateWithAddedBusinessDays(selected_ship_date, target_business_days) : null;

  if (selected_case_or_ireq) {
    return formatShippoRates(selected_case_or_ireq.shippo_rates, target_arrival_date, selected_ship_date);
  }

  return [];
}

/**
 * Formats & sorts shippo rates by retrieving estimated arrival date and determining if it's past the target arrival date or not
 * @function
 * @param {object} selected_case_or_ireq - Selected case or item request object
 * @param {string} target_arrival_date - Target arrival date for case/ireq
 * @param {string} selected_ship_date - Current selected ship date
 * @returns {Array} Formatted shippo rates
 */
function formatShippoRates(shippo_rates, target_arrival_date, selected_ship_date) {
  let preferred_rates = [];
  let on_time_rates = [];
  let late_rates = [];
  let other_rates = [];

  for (const rate of shippo_rates) {
    rate.estimated_arrival_date = rate.estimated_days ? getDateWithAddedBusinessDays(selected_ship_date, rate.estimated_days) : null;
    rate.is_past_target_arrival_date = rate.estimated_arrival_date && target_arrival_date ? rate.estimated_arrival_date > target_arrival_date : null;

    if (rate.preferred_method) {
      preferred_rates.push(rate);
    } else if (rate.is_past_target_arrival_date) {
      late_rates.push(rate);
    } else if (rate.is_past_target_arrival_date !== null) {
      on_time_rates.push(rate);
    } else {
      other_rates.push(rate);
    }
  }
  return preferred_rates.concat(on_time_rates).concat(late_rates).concat(other_rates);
}

/**
 * Retrieves item request's approval date from case details
 * @param cases_details {Object} - Case details object
 * @param item_request {Object} - Item request object
 * @returns {String} The approval date
 */
function getIrApprovalDate(case_details, item_request) {
  const { ireq_id } = item_request;
  for (const status of case_details.item_request_status) {
    if (status.ireq_id === ireq_id && status.ireq_status === 'INBRACE Manufacturing Item Request') {
      return status.created_date;
    }
  }
  return '';
}

/**
 * Gets selected case from case details
 * @function
 * @param {string} case_id - Case id
 * @param {object} case_details - Case details object
 * @return {Object} Selected case
 */
function getSelectedCase(case_id, case_details) {
  for (const cases of case_details.cases) {
    if (cases.case_id === case_id) {
      return cases;
    }
  }
  return null;
}
